<template>
  <div>
    <b-table
      :fields="tabela.fields"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      responsive="sm"
      head-variant="light"
      :items="items"
      class="mt-3"
      show-empty
      striped
      hover
    >
      <template #cell(acoes)="item">
        <div class="flex">
          <b-dropdown variant="outline-default">
            <b-dropdown-item @click="editar(item.item)">
              {{ $t('GERAL.EDITAR') }}
            </b-dropdown-item>
            <b-dropdown-item @click="excluir(item.item)">
              {{ $t('GERAL.EXCLUIR') }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import helpers from '@/common/utils/helpers';
export default {
  name: 'TabelaPeriodoLetivo',
  props: {
    items: {
      type: Array,
      default: Array,
    },
  },

  data() {
    return {
      tabela: {
        fields: [
          {
            key: 'ano',
            label: 'Ano letivo',
            sortable: true,
          },
          {
            key: 'dataInicio',
            label: 'Início letivo',
            sortable: true,
            formatter: (value) => {
              return helpers.formatarDataBr(value);
            },
          },
          {
            key: 'dataFim',
            label: 'Fim letivo',
            sortable: true,
            formatter: (value) => {
              return helpers.formatarDataBr(value);
            },
          },
          {
            key: 'dataInicioMatricula',
            label: 'Início matrícula',
            sortable: true,
            formatter: (value) => {
              return helpers.formatarDataBr(value);
            },
          },
          {
            key: 'dataFimMatricula',
            label: 'Fim matrícula',
            sortable: true,
            formatter: (value) => {
              return helpers.formatarDataBr(value);
            },
          },
          {
            key: 'statusLiberacaoDescricao',
            label: 'Status',
            sortable: true,
          },
          {
            key: 'acoes',
            label: 'Ações',
            sortable: true,
          },
        ],
      },
    };
  },
  methods: {
    editar(item) {
      this.$emit('editar', 'editar-periodo-letivo', item.id);
    },
    excluir(item) {
      this.$emit('excluir', item);
    },
  },
};
</script>
