<template>
  <div>
    <titulo-periodo-letivo @cadastrar="irPara"/>
    <tabela :items="items" @editar="irPara" @excluir="excluir" />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import Permisoes from '@/components/mixins/permissoes';
import breadcrumb from '@/common/utils/breadcrumb';
import mensagem from '@/common/utils/mensagem';
import rotas from '@/common/utils/rotas';

// Services:
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service.js';

// Components:
import Tabela from './components/Tabela.vue';
import TituloPeriodoLetivo from '@/views/periodos-letivos/periodo-letivo/components/TituloPeriodoLetivo'

export default {
  mixins: [Permisoes],
  components: {
    Tabela,
    TituloPeriodoLetivo,
  },
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    breadcrumb.definir(this.$store, [
      { titulo: this.$t('ACADEMICO_HOME.PAINEL_ACADEMICO') },
      { titulo: this.$t('ACADEMICO.ESTRUTURA_CEJ') },
      { titulo: this.$t('ACADEMICO.PERIODO_LETIVO') },
    ]);
    this.getAnosLetivos();
  },
  methods: {
    getAnosLetivos() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar()
        .then(({ data }) => {
          this.items = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    excluir(item) {
      mensagem
        .confirmacao(null, this.$t('Deseja excluir período letivo?'))
        .then(async (result) => {
          if (result.value) {
            this.$store.dispatch(START_LOADING);
            AnoLetivoService.excluir(item.id)
              .then(() => {
                this.getAnosLetivos();
                mensagem.sucesso(
                  this.$t('GERAL.SUCESSO'),
                  this.$t('Período excluído com sucesso')
                );
              })
              .catch((err) => {
                mensagem.showErrors(err);
              })
              .finally(() => {
                this.$store.dispatch(STOP_LOADING);
              });
          }
        });
    },
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
